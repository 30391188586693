const HOST = process.env.REACT_APP_HOST_NAME || ''
const API_VERSION = "/api/v1"

const URL = {
    LOGIN : HOST + "/login",
    LOGOUT : HOST + "/logout",
    GET_USER : HOST + "/api/users",
    SEARCH : HOST + "/api/v1/property_search/list",
    SINGLE_PROPERTY : HOST + "/api/v1/property_search/details", 
    SIGNUP : HOST + "/signup",
    SCHEDULE_TOUR : HOST +  "/schedule_tour",
    OFFER : HOST + "/offer",
    GET_ACCOUNT : HOST + "/api/secure/v1/user",
    UPDATE_ACCOUNT : HOST + "/api/secure/v1/user",
    DELETE_ACCOUNT : HOST + "/api/secure/v1/user",
    LIKE_PROPERTY : HOST + "/api/liked_properties",
    SIMILAR_PROPS : HOST + API_VERSION + "/property_search/similar_properties?prop_features="
}

export default URL